import { getFromStorage } from "../utils/imageUtil";

export interface IAppImage {
  getImage: (level?: string | number) => any,
  value: string,
  alt: string,
}

export const genderImages: IAppImage[] = [
  {
    getImage: () => {
      return getFromStorage(`female.webp`);
    },
    value: "female",
    alt: "app.character.female"
  },
  {
    getImage: () => {
      return getFromStorage(`male.webp`);
    },
    value: "male",
    alt: "app.character.male",
  },
];

export const maleGenderImages: IAppImage[] = [
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/dark_elf/${ level }.webp`);
    },
    value: "dark_elf",
    alt: "app.character.dark_elf"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/gnome/${ level }.webp`);
    },
    value: "gnome",
    alt: "app.character.gnome"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/human/${ level }.webp`);
    },
    value: "human",
    alt: "app.character.human"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/light_elf/${ level }.webp`);
    },
    value: "light_elf",
    alt: "app.character.light_elf"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/male/orc/${ level }.webp`);
    },
    value: "orc",
    alt: "app.character.orc"
  },
];

export const femaleGenderImages: IAppImage[] = [
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/dark_elf/${ level }.webp`);
    },
    value: "dark_elf",
    alt: "app.character.dark_elf"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/gnome/${ level }.webp`);
    },
    value: "gnome",
    alt: "app.character.gnome"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/human/${ level }.webp`);
    },
    value: "human",
    alt: "app.character.human"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/light_elf/${ level }.webp`);
    },
    value: "light_elf",
    alt: "app.character.light_elf"
  },
  {
    getImage: (level) => {
      return getFromStorage(`characters/female/orc/${ level }.webp`);
    },
    value: "orc",
    alt: "app.character.orc"
  },
];

export const roleImages: IAppImage[] = [
  {
    getImage: () => {
      return getFromStorage(`dark_elf.webp`);
    },
    value: "dark_elf",
    alt: "app.character.dark_elf"
  },
  {
    getImage: () => {
      return getFromStorage(`gnome.webp`);
    },
    value: "gnome",
    alt: "app.character.gnome"
  },
  {
    getImage: () => {
      return getFromStorage(`human.webp`);
    },
    value: "human",
    alt: "app.character.human"
  },
  {
    getImage: () => {
      return getFromStorage(`light_elf.webp`);
    },
    value: "light_elf",
    alt: "app.character.light_elf"
  },
  {
    getImage: () => {
      return getFromStorage(`orc.webp`);
    },
    value: "orc",
    alt: "app.character.orc"
  },
];
