import React, { useEffect, useState } from "react";
import { getFromStorage } from "../../../utils/imageUtil";
import { useTranslation } from "react-i18next";
import Balance from "../Balance/Balance";

const Wallet = () => {
  const [t] = useTranslation();


  return (
    <>
      <div className="overflow-y-auto p-4 grow flex flex-col gap-4 items-center">
        <div className="w-full">
          <div className={'inline-flex gap-2 mb-4'}>
            <Balance
              src={''}
              userCoins={100}
              alt={'ton'}
              imgClass={'h-6'}
              textClass={'font-semibold text-xl leading-6'}
            ></Balance>
          </div>
        </div>

      </div>
    </>
  );
};

export default Wallet;
