import { useTranslation } from "react-i18next";
import { RPSMoveOption, BattleStage } from "../../../types/types";
import { getFromStorage } from "../../../utils/imageUtil";

interface IFightSelectOptionProps {
  battleStage: string;
  handleGameOptionClick: (option: RPSMoveOption) => void;
  handleGameOptionSubmit: () => void;
  gameOption?: string;
}

const FightSelectOption = ({
  battleStage,
  handleGameOptionClick,
  handleGameOptionSubmit,
  gameOption,
}: IFightSelectOptionProps) => {

  const { t } = useTranslation();

  return (
    <div className="">
      <div className="flex justify-around my-2 gap-2">
        {Object.entries(RPSMoveOption).map(([key, value]) => (
          <label
            key={key}
            className={`basis-full rounded-xl flex gap-1 items-center justify-center text-yellow-300 font-light py-2 text-xl bg-graySecondary 
              ${value === gameOption && "shadow-goldSecondary "} 
            `}
            htmlFor={key}
          >
            <img
              className={` w-5 h-5 object-contain`}
              src={getFromStorage(`${key.toLowerCase()}.webp`)}
              alt={t(`app.fight.${key.toLowerCase()}`)}
            />
            {t(`app.fight.${key.toLowerCase()}`)}
            <input
              type="radio"
              id={key}
              name="game-option"
              value={key}
              checked={gameOption === key}
              onChange={() => handleGameOptionClick(value as RPSMoveOption)}
              className="hidden"
            />
          </label>
        ))}
      </div>
      <button
        className="bg-green-950 disabled:bg-opacity-70 font-semibold w-full rounded-2xl py-3 px-6 h-12 block relative overflow-hidden clickable text-xl"
        disabled={battleStage !== BattleStage.STARTED || !gameOption}
        onClick={handleGameOptionSubmit}
      >
        {t("app.components.select").toUpperCase()}
      </button>
    </div>
  );
};

export default FightSelectOption;
