import { Pages } from "../../../app/pages";
import React from "react";
import { useTranslation } from "react-i18next";
import { getFromStorage } from "../../../utils/imageUtil";

interface NavBarProps {
  activePage: string;
  setActivePage: (page: Pages) => void;
}

const NavBar = ({ activePage, setActivePage }: NavBarProps) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-2xl shadow-md border-t bg-stone-800 border-gray-700 z-1000">
      <div className="flex justify-around items-center h-16">
        <button
          onClick={() => setActivePage(Pages.BOOST)}
          className={`flex flex-col items-center w-8 text-nowrap ${activePage === Pages.BOOST ? 'text-white' : 'text-gray-500'}`}
        >
          <img
            className="h-8 w-8"
            alt={Pages.BOOST}
            src={getFromStorage(`boost.webp`)}
          />
          <span className="text-xs">{t(`app.nav.${Pages.BOOST}`)}</span>
        </button>
        <button
          onClick={() => setActivePage(Pages.FRIENDS)}
          className={`flex flex-col items-center w-8 text-nowrap ${activePage === Pages.FRIENDS ? 'text-white' : 'text-gray-500'}`}
        >
          <img
            className="h-8 w-8"
            alt={Pages.FRIENDS}
            src={getFromStorage(`friend.webp`)}
          />
          <span className="text-xs">{t(`app.nav.${Pages.FRIENDS}`)}</span>
        </button>
        <button
          onClick={() => setActivePage(Pages.HOME)}
          className={`flex flex-col items-center w-8 text-nowrap ${activePage === Pages.HOME ? 'text-white' : 'text-gray-500'}`}
        >
          <img
            className="h-8 w-8"
            alt={Pages.HOME}
            src={getFromStorage(`home.webp`)}
          />
          <span className="text-xs">{t(`app.nav.${Pages.HOME}`)}</span>
        </button>
        <button
          onClick={() => setActivePage(Pages.TASKS)}
          className={`flex flex-col items-center w-8 text-nowrap ${activePage === Pages.TASKS ? 'text-white' : 'text-gray-500'}`}
        >
          <img
            className="h-8 w-8"
            alt={Pages.TASKS}
            src={getFromStorage(`tasks.webp`)}
          />
          <span className="text-xs">{t(`app.nav.${Pages.TASKS}`)}</span>
        </button>
        <button
          onClick={() => setActivePage(Pages.STATS)}
          className={`flex flex-col items-center w-8 text-nowrap  ${activePage === Pages.STATS ? 'text-white' : 'text-gray-500'}`}
        >
          <img
            className="h-8 w-8"
            alt={Pages.STATS}
            src={getFromStorage(`stats.webp`)}
          />
          <span className="text-xs">{t(`app.nav.${Pages.STATS}`)}</span>
        </button>
      </div>
    </div>
  )
}

export default NavBar;
