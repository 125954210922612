import { IDailyBonusModel } from "../../../types/types";
import Balance from "../Balance/Balance";
import { getFromStorage } from "../../../utils/imageUtil";

interface DailyBonusCellProps {
  dailyBonusModel: IDailyBonusModel;
  header: string;
  claimed?: boolean;
  current?: boolean;
  timeRemaining?: string | null;
}

const DailyBonusCell = ({ dailyBonusModel, header, claimed, current, timeRemaining }: DailyBonusCellProps) => {
  return (
    <div className={`flex flex-col w-full h-32 rounded-xl justify-center items-center p-4 gap-2 ${claimed && !current && 'bg-green-900'} ${current && !claimed &&  'bg-amber-900'} ${!claimed && !current && 'bg-graySecondary'}`}>
      <span className={"font-bold"}>{header}</span>
      <div className={"flex items-center gap-2 h-8 w-full justify-center font-semibold"}>
        <Balance
          src={getFromStorage('coin.webp')}
          alt={''}
          userCoins={dailyBonusModel.bonus}
          imgClass={"h-5 w-5"}
          textClass={"font-bold"}
        />
      </div>
      <span>{timeRemaining}</span>
    </div>
  )
}

export default DailyBonusCell;
