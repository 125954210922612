import { ActionType, ITaskDTO, IUserModel } from "../../../types/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { getImageByTaskType, getTaskHeader } from "../../../utils/task";
import { getFromStorage } from "../../../utils/imageUtil";

interface TaskListItemProps {
  task: ITaskDTO;
  user: IUserModel;
  loading: boolean;
  isTaskCompleted: (task: ITaskDTO) => boolean;
  completeTask: (taskId: number) => Promise<void>;
  startTask: (taskId: number) => Promise<void>;
}

const TaskListItem = ({ task, user, loading, isTaskCompleted, startTask, completeTask }: TaskListItemProps) => {
  const [t] = useTranslation();

  return (
    <li
      className={"bg-gray-primary rounded-2xl"}
      key={task.id}
    >
      <div className={"flex p-2"}>
        <div className={"ml-2 mr-4 my-auto"}>
          <img
            src={getImageByTaskType(task.type)}
            className={"w-12 h-12 min-w-12"}
            alt="avatar"
          />
        </div>
        <div className={"flex flex-col justify-center"}>
          {task.action !== undefined ?
            <p className={'font-semibold'}>
              {getTaskHeader(task, t)}
            </p>
            :
            <p className={'font-semibold'}>
              {t(`app.tasks.actionType.`, { value: task.header })}
            </p>
          }
          {
            !user.finishedTasksIds.includes(task.id) &&
            <>
              {(task.action === ActionType.MINE || task.action === ActionType.INVITE) &&
                <div className={"flex items-center"}>
                  <p>
                    {t(`app.tasks.${task.action.toLowerCase()}`, {
                      value: task.action === ActionType.MINE ?
                        user.coins : user.friendsIds.length,
                      value1: task.payload
                    })}
                  </p>
                </div>
              }
              <div className={"flex items-center"}>
                <p>
                  {t('app.tasks.reward')}
                </p>
                <img
                  className="h-4 w-4 ml-2 mr-1"
                  alt={'coins:'}
                  src={getFromStorage(`coin.webp`)}
                />
                <p>
                  {task.reward}
                </p>
              </div>
            </>
          }
        </div>
        <div
          className={"text-xs text-center w-16 bg-gray-800 max-w-16 min-w-16 rounded-full h-fit shadow-md overflow-hidden ml-auto my-auto"}
        >

          {loading ?
            <div className={"w-fit mx-auto py-1"}>
              <div className={"loader h-6 w-6"}></div>
            </div>
            :
            user.finishedTasksIds.includes(task.id) ?
              <p className={"bg-gray-800 w-full h-full py-1"}>{t('app.tasks.done')}</p>
              :
              user.startedTasksIds.includes(task.id) || task.action === ActionType.MINE || task.action === ActionType.INVITE ?
                <button
                  className={`w-full h-full py-1 ${isTaskCompleted(task) && "clickable bg-amber-900 "}`}
                  onClick={() => completeTask(task.id)}
                >
                  {t('app.tasks.claim')}
                </button>
                :
                <button
                  className={"bg-green-900 w-full h-full py-1 clickable"}
                  onClick={() => startTask(task.id)}
                >
                  {t('app.tasks.start')}
                </button>
          }
        </div>
      </div>
    </li>
  );
}

export default TaskListItem;
