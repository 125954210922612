import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ActionType, IContext, Section, ITaskDTO, TaskType } from "../../types/types";
import { useTranslation } from "react-i18next";
import TaskListItem from "../_components/TaskListItem/TaskListItem";
import { mapActionType } from "../../utils/transformation";
import { finishTaskApi, startTaskApi } from "../../api/apiFunctions";

type TaskCategory = 'main' | 'promo' | 'socials' | 'other' | 'finished';

const Tasks = () => {

  const [t] = useTranslation();
  const { user, setUser, tasks } = useOutletContext<IContext>();
  const [tasksInLoading, setTasksInLoading] = useState<number[]>([]);
  const [currentSection, setCurrentSection] = useState<TaskCategory>('main');

  const onSectionClick = (sectionId: TaskCategory) => {
    setCurrentSection(sectionId);
  }

  const handleTask = (task: ITaskDTO) => {
    switch (mapActionType(task.action)) {
      case ActionType.CONNECT:
      case ActionType.JOIN:
      case ActionType.WATCH:
      case ActionType.SUBSCRIBE:
      case ActionType.REDIRECT:
        if (task.type === TaskType.TELEGRAM) {
          Telegram.WebApp.openTelegramLink(task.payload);
        } else {
          Telegram.WebApp.openLink(task.payload);
        }
        break;
      default:
        break;
    }
  };

  const isTaskCompleted = (task: ITaskDTO) => {
    switch (mapActionType(task.action)) {
      case ActionType.MINE:
        return Number(user.coins) >= Number(task.payload);
      case ActionType.INVITE:
        return Number(user.friendsIds.filter((f => f !== user.referredBy)).length) >= Number(task.payload);
      default:
        return true;
    }
  };

  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const startTask = async (taskId: number) => {
    const task = tasks.find(t => t.id === taskId);

    if (task) {
      try {
        setTasksInLoading([...tasksInLoading, taskId]);
        await startTaskApi({ taskId: taskId });
        handleTask(task);
        setUser({ ...user, startedTasksIds: [...user.startedTasksIds, taskId] });
        await wait(60000);
      } catch (e) {
        console.error(e);
      } finally {
        if (!task.isRequired) {
          setTasksInLoading(tasksInLoading.filter(id => id !== taskId));
        }
      }
    }
  };

  const completeTask = async (taskId: number) => {
    const task = tasks.find(t => t.id === taskId);
    if (task) {
      if (!isTaskCompleted(task)) return;

      try {
        await finishTaskApi({ taskId: taskId });
        const userModel = {
          ...user,
          ...{
            finishedTasksIds: [...user.finishedTasksIds, taskId],
            coins: Number(user.coins) + Number(task.reward)
          }
        }
        setUser(userModel);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const categorizedTasks: Record<TaskCategory, ITaskDTO[]> = {
    promo: tasks.filter(t => t.sectionTranslationKey === Section.PROMO && !user.finishedTasksIds.some(ft => ft === t.id)),
    socials: tasks.sort((a, b) => b.action.localeCompare(a.action)).filter(t => t.sectionTranslationKey === Section.SOCIALS && !user.finishedTasksIds.some(ft => ft === t.id)),
    main: tasks.filter(t => t.sectionTranslationKey === Section.TASKS && !user.finishedTasksIds.some(ft => ft === t.id)),
    other: tasks.filter(
      t => t.sectionTranslationKey !== Section.PROMO &&
        t.sectionTranslationKey !== Section.SOCIALS &&
        t.sectionTranslationKey !== Section.TASKS &&
        !user.finishedTasksIds.some(ft => ft === t.id)
    ),
    finished: tasks.filter(t => user.finishedTasksIds.some(ft => ft === t.id))
  };

  return (
    <div className="tasks px-4 pb-24">
      <h2 className="text-3xl font-semibold py-4">{t('app.tasks.')}</h2>
      <div className="flex justify-around gap-1 p-2 items-center w-full rounded-2xl shadow-md border-t bg-stone-800 border-gray-700 z-50 sticky top-6 appearance-left">
        {
          Object.keys(categorizedTasks).map((category) => (
            <button
              key={`${category}-key`}
              onClick={() => onSectionClick(category as TaskCategory)}
              className={`w-full py-1 rounded-lg transition-colors ${category === currentSection ? 'bg-gray-600 text-white' : 'text-gray-500'}`}
            >
              {t(`app.tasks.${category}`)}
            </button>
          ))
        }
      </div>
      <h3 className={"text-2xl font-semibold py-2 appearance-left"}>{t(`app.tasks.${currentSection}`)}</h3>
      <ul className={"flex flex-col gap-2 pb-2 appearance-top"}>
        {currentSection && categorizedTasks[currentSection].length > 0 ?
          categorizedTasks[currentSection]?.map((task) =>
            (
              <TaskListItem
                key={task.id}
                task={task}
                user={user}
                loading={tasksInLoading.includes(task.id) || (user.startedTasksIds.includes(task.id) && task.isRequired && (!user.approvedTasksIds || !user.approvedTasksIds?.includes(task.id)))}
                isTaskCompleted={isTaskCompleted}
                completeTask={completeTask}
                startTask={startTask}
              />
            ))
          :
          <p className={"text-xl"}>{t('app.tasks.youHaveNoTasks')}</p>
        }
      </ul>
    </div>
  );
};

export default Tasks;
