import { ReactNode } from "react";

export interface IUserModel {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  languageCode: string;
  photoUrl: string;
  gender: string;
  race: string;
  referredBy: number | null;
  friendsIds: number[];
  startedTasksIds: number[];
  approvedTasksIds: number[] | null;
  finishedTasksIds: number[];
  coins: number;
  level: number;
  miningPower: number;
  miningStartPower: number | null;
  miningTime: number;
  miningStart: string | null;
  miningFinish: string | null;
  lastLogin: number;
  lastBonusClaimed: string | null;
  lastBonusClaimedId: number | null;
  thiefId: number;
  lostCoins: number;
  stolenAt: string;
  lastStealFromAt: string;
  lastBattleStartedAt: string;
  thiefPower: number;
  thiefProtection: number;
}

export interface IDailyBonusModel {
  id: number;
  bonus: number;
}

export interface ICardModel {
  id: number;
  sectionId: number;
  translationKey: string;
  baseBoost: number;
  basePrice: number;
  boostMultiplier: number;
  maxLevel: number;
  priceMultiplier: number;
  priceMultiplierBase: number;
}

export interface ICardRequirementModel {
  cardId: number | null;
  sectionId: number | null;
  requirementOwnerId: number;
  translationKey: string;
  type: string;
  cardLevel: number | null;
}

export interface ICardSectionModel {
  id: number;
  ordering: number;
  translationKey: string;
}

export interface IReceiptModel {
  id: number;
  userId: number;
  cardId: number;
  sectionId: number;
  boost: number;
  nextLevelBoost: number;
  totalCardBoost: number;
  price: number;
  nextLevelPrice: number;
  level: number;
  timestamp: number;
  totalThiefPower: number;
  nextLevelThiefPower: number;
  totalThiefProtection: number;
  nextLevelThiefProtection: number;
}

export interface ITaskDTO {
  id: number;
  action: string;
  header: string;
  level: number;
  payload: string;
  reward: number;
  sectionTranslationKey: string;
  type: string;
  telegramChannelId: number;
  isRequired: boolean;
}

export interface IRequireable {
  id: number;
  requirement: ICardRequirementModel | null;
}

export interface IUserBoostCardSection extends IRequireable {
  ordering: number;
  translationKey: string;
  cards: IUserBoostCard[];
}

export interface IUserBoostCard extends IRequireable {
  sectionId: number;
  translationKey: string;
  level: number;
  price: number;
  nextLevelBoost: number;
  totalBoost: number;
  priceMultiplier: number;
  basePriceMultiplier: number;
  boostMultiplier: number;
  maxLevel: number;
}

export enum RequirementType {
  CARD = "CARD",
  TASK = "TASK",
}

export enum ActionType {
  SUBSCRIBE = 'SUBSCRIBE',
  WATCH = 'WATCH',
  JOIN = 'JOIN',
  CONNECT = 'CONNECT',
  MINE = 'MINE',
  INVITE = 'INVITE',
  REDIRECT = 'REDIRECT'
}

export enum TaskType {
  INSTAGRAM = 'INSTAGRAM',
  TELEGRAM = 'TELEGRAM',
  TIKTOK = 'TIKTOK',
  X = 'X',
  YOUTUBE = 'YOUTUBE',
  PARTNER = 'PARTNER'
}

export enum Section {
  PROMO = 'PROMO',
  TASKS = 'TASKS',
  SOCIALS = 'SOCIALS'
}

export interface IContext {
  user: IUserModel;
  setUser: (user: IUserModel) => void;
  thief: IUserSafeModel;
  greetingsShowed: boolean;
  setGreetingsShowed: (greetingsShowed: boolean) => void;
  userBoostCardSections: IUserBoostCardSection[];
  addReceiptsToReceiptStates: (receipt: IReceiptModel[]) => void;
  isMiningStarted: boolean;
  isMiningFinished: boolean;
  coins: number;
  handleMining: () => void;
  tasks: ITaskDTO[];
  dailyBonusModels: IDailyBonusModel[];
  claimBonus: (dailyBonusModel: IDailyBonusModel) => Promise<void>;
  timeToUnlockBonusButton: string | null;
  setTimeToUnlockBonusButton: (text: string | null) => void;
}

export interface ErrorBoundaryProps {
  children: ReactNode;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum Race {
  DARK_ELF = "DARK_ELF",
  GNOME = "GNOME",
  HUMAN = "HUMAN",
  LIGHT_ELF = "LIGHT_ELF",
  ORC = "ORC"
}

export interface IUserSafeModel {
  id: number;
  level: number;
  race: string;
  gender: string;
  firstName: string;
  lastName: string;
  username: string;
  photoUrl: string;
  miningPower: number;
  lostCoins: number;
  thiefPower: number;
  thiefProtection: number;
}

export interface IThiefModel extends IUserSafeModel {
  totalStolenCoins: number;
}

export interface InitialDataResponse {
  user: IUserModel;
  thief: IUserSafeModel;
  dailyBonus: IDailyBonusModel[];
  cardSections: ICardSectionModel[];
  requirements: ICardRequirementModel[];
  cards: ICardModel[];
  tasks: ITaskDTO[];
  receiptModels: IReceiptModel[];
}

export interface FinishTaskRequest {
  taskId: number;
}

export interface StartTaskRequest {
  taskId: number;
}

export interface GetFriendsResponse {
  friends: IUserSafeModel[];
}

export interface GetStatsResponse {
  totalTopWorldUsers: number;
  topWorldUserModels: IUserSafeModel[];
  totalTopInLevelUsers: number;
  topInLevelUserModels: IUserSafeModel[];
  thiefModels: IThiefModel[];
}

export interface PurchaseCardRequest {
  cardId: number;
}

export interface SetCharacterRequest {
  race: string;
  gender: string;
}

export interface IStealCoinsResponse {
  thief: IUserModel;
  victim: IUserSafeModel;
}

export interface IInternalChatMessage {
  text: string;
  incoming: boolean;
}

export enum BattleStage {
  STARTED = "STARTED",
  NOT_STARTED = "NOT_STARTED",
  FINISHED = "FINISHED"
}

export enum RPSMoveOption {
  ROCK = "ROCK",
  PAPER = "PAPER",
  SCISSORS = "SCISSORS"
}

export interface BattleModelResponse {
  battle: BattleModel;
  error: ErrorResponse | null;
}

export interface FinishBattleResponse {
  result: BattleResultModel;
  error: ErrorResponse | null;
}

export interface MakeMoveResponse {
  move: BattleMoveModel;
  error: ErrorResponse | null;
}

export interface ErrorResponse {
  code: number;
  message: string;
  data: any;
}

export interface BattleModel {
  id: number;
  userId: number;
  started: string;
  duration: number | null;
  maxTurns: number;
  enemy: number;
  turn: number;
  score: number;
  bet: string; // Using string to represent BigDecimal
  finished: boolean;
}

export interface BattleResultModel {
  result: number;
  reward: string; // Using string to represent BigDecimal
}

export interface BattleMoveModel {
  turn: number;
  move: number;
  enemyMove: number;
  result: number;
}

export interface StartBattleRequest {
  bet: string; // BigDecimal is represented as a string
}

export interface FinishBattleRequest {
  battleId: number;
}

export interface RemoteEventModel {
  id: number;
  timestamp: string;
  type: string;
  payload: Record<string, any>;
}

export enum BetCurrency {
  XTR = 'XTR',
  // TON = 'TON',
}

export interface CreateMatchRequest {
  currency: BetCurrency;
  bet: number;
}

export enum Status {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  CANCELLED = 'CANCELLED',
}

export interface MatchModel {
  id: number;
  creatorId: IUserSafeModel;
  currency: BetCurrency;
  bet: string;
  status: Status;
  turn: number;
  acceptorId: IUserSafeModel | null;
  created: string;
  started: string | null;
  ended: string | null;
  winnerId: number | null;
}

export interface MatchModelResponse {
  result: MatchModel;
  error: ErrorResponse | null;
}

export interface MakeMoveRequest {
  battleId?: number;
  matchId?: number;
  option: RPSMoveOption;
}

export interface AcceptMatchRequest {
  matchId: number;
}