import './BoostCard.css'
import { IUserBoostCard } from "../../../types/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatAndRoundPrice } from "../../../utils/money";
import { getFromStorage } from "../../../utils/imageUtil";

export interface BoostCardProps {
  card: IUserBoostCard,
  getUnlockMessage: (card: IUserBoostCard) => string,
  onClick?: () => void,
  className?: string,
}

const BoostCard = ({
  card,
  getUnlockMessage,
  className,
  onClick,
}: BoostCardProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`relative overflow-hidden flex flex-col boost-card text-xs rounded-2xl ${className ?? ''}`}
      key={card.id}
      onClick={card.level < card.maxLevel ? onClick : undefined}
    >
      <div className={"boost-card__top-block"}>
        <div className="boost-card__image m-2">
          <img
            alt={card.id.toString()}
            src={getFromStorage(`cards/${card.translationKey}.webp`)}
          />
        </div>
        <div className="flex flex-col justify-between py-1 pr-1">
          <div className="w-full font-bold h-8 overflow-hidden">
            {t(`app.boost.cards.${card.translationKey}`)}
          </div>
          {
            card.level < card.maxLevel &&
            (
              <div className="flex flex-col">
            <span className={"text-gray-400"}>
              {t(`app.boost.profitPerHour`)}
            </span>
                <div className="flex font-bold">
            <span className={"font-bold mr-1 leading-4"}>
              +
            </span>
                  <div className="w-4">
                    <img
                      src={getFromStorage(`coin.webp`)}
                      alt="coin"
                    />
                  </div>
                  <div className="leading-4 pl-1">
                    {formatAndRoundPrice(card.nextLevelBoost - card.totalBoost)}
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className={'flex items-center h-8'}>
        {
          card.level < card.maxLevel ? (

            <>
            <span className="h-5 leading-5 px-2">
          {t('app.boost.level', { value: card.level })}
        </span>
              <div className="boost-card__purchase-button flex font-bold px-2">
                <div className="w-5">
                  <img
                    src={getFromStorage(`coin.webp`)}
                    alt="coin"
                  />
                </div>
                <div className="pl-1 leading-5">
                  {formatAndRoundPrice(card.price)}
                </div>
              </div>
            </>
          ) : (
            <>
              <span className="h-5 leading-5 px-2 w-full text-center">
                {t('app.boost.level', { value: card.level })}
              </span>
            </>
          )
        }

      </div>
      {
        card.requirement && (
          <div className={'absolute flex flex-col p-2 justify-between items-center bg-gray-600 opacity-90 h-full w-full'}>
            <div className={'w-10'}>
              <img
                src={getFromStorage(`lock.svg`)}
                alt="lock"
              />
            </div>
            <div className={'text-center text-md font-semibold'}>
              {getUnlockMessage(card)}
            </div>
          </div>
        )
      }
    </div>
  )
}

export default BoostCard;
