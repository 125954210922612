import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import './app/i18n.ts';
import App from './App';
import Home from './components/pages/Home';
import Friends from './components/pages/Friends';
import Tasks from './components/pages/Tasks';
import Boost from './components/pages/Boost';
import Stats from './components/pages/Stats';
import ErrorBoundary from './components/_components/ErrorBoundary/ErrorBoundary';
import { Pages } from "./app/pages";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    children: [
      {index: true, element: <Home/>},
      {path: Pages.FRIENDS, element: <Friends/>},
      {path: Pages.BOOST, element: <Boost/>},
      {path: Pages.TASKS, element: <Tasks/>},
      {path: Pages.STATS, element: <Stats/>}
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <TonConnectUIProvider manifestUrl="https://venividistorage.blob.core.windows.net/assets/tonconnect-manifest.json">
        <RouterProvider router={ router }/>
      </TonConnectUIProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
