import { mapTaskType } from "./transformation";
import { ITaskDTO, TaskType } from "../types/types";
import { TFunction } from "i18next";
import { getFromStorage } from "./imageUtil";

export const getImageByTaskType = (type: string) => {
  switch (mapTaskType(type)) {
    case TaskType.INSTAGRAM:
      return getFromStorage(`instagram.webp`);
    case TaskType.TELEGRAM:
      return getFromStorage(`telegram.webp`);
    case TaskType.X:
      return getFromStorage(`twitter-x.webp`);
    case TaskType.YOUTUBE:
      return getFromStorage(`youtube.webp`);
    case TaskType.TIKTOK:
      return getFromStorage(`tiktok.webp`);
    default:
      return getFromStorage(`coin.webp`);
  }
};

export const getTaskHeader = (task: ITaskDTO, t: TFunction<"translation", undefined>): string => {
  return t(`app.tasks.actionType.${ task.action.toLowerCase() }`, {
    value: task.header ?? "",
    value1: task.type !== undefined ? t(`app.tasks.taskType.${ task.type.toLowerCase() }`) : ""
  })
}
